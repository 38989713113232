import { useEffect, useState } from 'react'

import axios from 'axios'

import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { useSearchParams } from 'next/navigation'

import { useGoogleLogin } from '@react-oauth/google'
import { useApple } from '../../packages/ui/src/lib/apple'
import * as Sentry from '@sentry/nextjs'

import Loader from '../../components/loader'
import Image from 'next/image'

import WelcomeLayout from '../../components/redesign/WelcomeLayout'

import TCModal from '../../modules/sign-up/TCModal'
import ProviderTCModal from '../../modules/sign-up/ProviderTCModal'

import { useUser } from '../../contexts/userContext'
import setUser from '../../actions/setUser'
import Input from '@howdy/core/elements/Input'
import PasswordInput from '../../components/redesign/PasswordInput'
import Checkbox from '@howdy/core/elements/Checkbox'
import Button from '@howdy/core/elements/Button'
import LinkButton from '../../components/redesign/Link'
import EnvelopeSimple from '@howdy/core/elements/Icons/EnvelopeSimple'
import useMobile from '../../hooks/useMobile'
import { errorToast } from '@howdy/core/lib/toast'

export default function SignUp() {
  const {
    userState: { data },
    dispatch,
  } = useUser()
  const router = useRouter()
  const { signupMethod } = router.query
  const { email: queryEmail } = router.query
  const [showTC, setShowTC] = useState(false)
  const [showTCProvider, setShowTCProvider] = useState(false)
  const [TCAccepted, setTCAccepted] = useState(false)
  const [signUpFlow, setSignUpFlow] = useState('email')
  const [showEmailSignUp, setShowEmailSignUp] = useState(false)
  const { isMobile } = useMobile()

  const [isLoading, setIsLoading] = useState(false)
  const searchParams = useSearchParams()

  const signUpForm = useForm({
    defaultValues: {
      email: queryEmail || '',
    },
  })

  useEffect(() => {
    if (data?.id && data?.role !== 'LimitedAccess') {
      router.replace('/dashboard')
    }
  }, [])

  useEffect(() => {
    if (signupMethod == 'google') {
      setShowTCProvider(true)
      setSignUpFlow('google')
    } else if (signupMethod == 'apple') {
      setShowTCProvider(true)
      setSignUpFlow('apple')
    } else if (signupMethod == 'email') {
      setShowEmailSignUp(true)
      setSignUpFlow('email')
    }
  }, [signupMethod])

  useEffect(() => {
    if (queryEmail) {
      signUpForm.setValue('email', queryEmail)
    }
  }, [queryEmail])

  const onSubmitSignUp = ({ email, password }) => {
    setIsLoading(true)

    axios
      .post('/partners/register', {
        password,
        email,
        url: window.location.href,
      })
      .then((response) => {
        dispatch(setUser({ email }))

        if (response?.data?.role === 'LimitedAccess') {
          router.replace('/my-teams/my-candidates')
        } else {
          router.query.redirect
            ? router.replace(router.query.redirect)
            : router.replace('/dashboard')
        }
      })
      .catch((err) => {
        console.log(err.response)
        Sentry.captureException(err)
        errorToast({
          title: 'Error registering account',
          message: err?.response?.data?.message || 'Error registering account',
        })
        setIsLoading(false)
      })
  }

  const onGoogleSuccess = useGoogleLogin({
    onSuccess: (response) => {
      const { access_token } = response
      axios
        .post('/partners/register', {
          googleId: access_token,
          url: window.location.href,
        })
        .then((registerResponse) => {
          dispatch(setUser({ email: registerResponse?.data?.email }))
          router.query.redirect
            ? router.replace(router.query.redirect)
            : router.replace('/dashboard')

          setIsLoading(false)
        })
        .catch((err) => {
          Sentry.captureException(err)
          errorToast({
            title: 'Login with google failed',
            message: err?.response?.data?.message || 'There was an error with google login',
          })
          setIsLoading(false)
        })
    },
  })

  const onAppleAuth = useApple({
    onSuccess: () => {
      window.top.location.href = `${searchParams.get('redirect') || '/dashboard'}`
    },
  })

  const onAcceptTC = () => {
    setShowTC(false)
    if (signUpFlow == 'email') {
      setTCAccepted(true)
    } else if (signUpFlow == 'apple') {
      onAppleAuth()
    } else {
      onGoogleSuccess()
    }
  }

  const onAcceptTCProvider = () => {
    setShowTCProvider(false)
    if (signUpFlow == 'apple') {
      onAppleAuth()
    } else {
      onGoogleSuccess()
    }
  }

  const onOpenTCfromProvider = () => {
    setShowTCProvider(false)
    setShowTC(true)
  }

  if (isLoading) {
    return <Loader loading={isLoading} />
  }

  const getQuery = () => {
    const query = router.query
    const queryMap = Object.keys(query).map((key) => `${key}=${encodeURIComponent(query[key])}`)
    return queryMap.length > 0 ? `?${queryMap.join('&')}` : ''
  }

  return (
    <WelcomeLayout pageTitle='Howdy | Sign up' title='Howdy!'>
      <div className='mt-9 flex w-full flex-col items-center pb-4 font-worksans lg:items-start'>
        <div className='mb-4 flex w-full flex-col gap-5 md:w-auto lg:items-start'>
          <Button
            fullWidth={true}
            childType={!isMobile ? 'leftAligned' : 'center'}
            onClick={() => {
              setSignUpFlow('google'), setShowTCProvider(true)
            }}
          >
            <div className='flex items-center gap-3'>
              <Image height={20} width={20} src='/google-icon.svg' />
              Create account with Google
            </div>{' '}
          </Button>
          <Button
            fullWidth={true}
            childType={!isMobile ? 'leftAligned' : 'center'}
            onClick={() => {
              setSignUpFlow('apple'), setShowTCProvider(true)
            }}
          >
            <span className='-mt-1 flex items-center gap-3'>
              <svg
                width='21'
                height='20'
                viewBox='0 0 21 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M17.989 6.81868C17.875 6.90869 15.8625 8.06281 15.8625 10.6291C15.8625 13.5974 18.4233 14.6475 18.5 14.6735C18.4882 14.7375 18.0932 16.1116 17.1498 17.5118C16.3086 18.7439 15.4301 19.974 14.0937 19.974C12.7572 19.974 12.4133 19.1839 10.8705 19.1839C9.36697 19.1839 8.83239 20 7.60994 20C6.38748 20 5.53452 18.8599 4.5538 17.4597C3.41782 15.8156 2.5 13.2613 2.5 10.8371C2.5 6.94869 4.98421 4.88649 7.42912 4.88649C8.72823 4.88649 9.81114 5.75458 10.6268 5.75458C11.4031 5.75458 12.6137 4.83448 14.0917 4.83448C14.6518 4.83448 16.6643 4.88649 17.989 6.81868ZM13.3901 3.18832C14.0013 2.45024 14.4337 1.42614 14.4337 0.40204C14.4337 0.260026 14.4219 0.116012 14.3963 0C13.4019 0.0380038 12.2187 0.674067 11.5053 1.51615C10.9452 2.16422 10.4224 3.18832 10.4224 4.22642C10.4224 4.38244 10.4479 4.53845 10.4597 4.58846C10.5226 4.60046 10.6248 4.61446 10.727 4.61446C11.6193 4.61446 12.7415 4.0064 13.3901 3.18832Z'
                  fill='white'
                />
              </svg>
            </span>
            <>Create account with Apple</>
          </Button>
          {!showEmailSignUp && (
            <Button
              fullWidth={true}
              childType={!isMobile ? 'leftAligned' : 'center'}
              style='secondary'
              leftIcon={'/EnvelopeSimple.svg'}
              onClick={() => {
                setShowEmailSignUp(true)
              }}
            >
              <div className='flex items-center gap-3'>
                <div className='group text-howdy-primary-green group-hover:text-white '>
                  <EnvelopeSimple width={20} height={20} color='currentColor' />
                </div>
                Create account with an email
              </div>
            </Button>
          )}
        </div>
        <form
          className={`flex w-full flex-col ${showEmailSignUp ? 'block' : 'hidden'}`}
          onSubmit={signUpForm.handleSubmit(onSubmitSignUp)}
        >
          <Input
            label='Email'
            type='email'
            placeholder='Enter email'
            name='email'
            defaultValue={queryEmail || ''}
            input={{
              ...signUpForm.register('email', {
                required: 'Required',
              }),
            }}
            error={signUpForm.errors && signUpForm.errors.email?.message}
          />
          <PasswordInput
            label='Password'
            type='password'
            placeholder='Enter password'
            name='password'
            error={signUpForm.errors && signUpForm.errors.password?.message}
            register={signUpForm.register('password', {
              required: 'Required',
              maxLength: {
                value: 80,
                message: 'Maximum length is 80',
              },
            })}
          />
          <div className='mb-4 mt-4 flex items-center justify-center gap-y-4 text-base lg:mt-6 lg:justify-start'>
            <Checkbox onChange={() => setTCAccepted(!TCAccepted)} value={TCAccepted} />
            <div className='mt-px flex items-baseline'>
              <p className='ml-2 mr-1.5 font-worksans font-normal'>Accept</p>
              <Button
                style={'tertiary'}
                onClick={() => {
                  setSignUpFlow('email'), setShowTC(true)
                }}
              >
                Terms and conditions
              </Button>
            </div>
          </div>
          <div className='flex w-full justify-center lg:justify-start'>
            <div>
              <Button type='submit' disabled={!TCAccepted}>
                Sign up
              </Button>
            </div>
          </div>
        </form>
        <div className='mt-4 flex justify-center xl:justify-start'>
          <LinkButton href={`/login${getQuery()}`} text={'LOGIN'} preText='Already a user? ' />
        </div>
        <div className='mt-4 flex justify-center text-base xl:justify-start'>
          <LinkButton
            href={'https://www.howdy.com/book-a-demo'}
            text={'Book a demo'}
            postText=' without creating an account'
          />
        </div>
      </div>
      <TCModal display={showTC} acceptAction={onAcceptTC} closeAction={() => setShowTC(false)} />
      <ProviderTCModal
        display={showTCProvider}
        acceptAction={onAcceptTCProvider}
        closeAction={() => setShowTCProvider(false)}
        openTCAction={onOpenTCfromProvider}
        provider={signUpFlow || 'google'}
      />
    </WelcomeLayout>
  )
}
